// import { get } from 'vuex-pathify'

var PassEntrycode = {
    methods: {
    },
    computed: {
        entrycode() {
            return Number(this.$route.params.entrycode)
        }
    }
}

export default PassEntrycode