import { get } from 'vuex-pathify'

var UnsavedGuard = {
    methods: {
        confirmLeave() {
            return window.confirm('Unsaved changes! Do you really want to leave?')
        },
        confirmStayInDirtyForm() {
            return this.isDirty && !this.confirmLeave()
        },
        beforeWindowUnload(e) {
            if (this.confirmStayInDirtyForm()) {
                // Cancel the event
                e.preventDefault()
                // Chrome requires returnValue to be set
                e.returnValue = ''
            }
        }
    },
    computed: {
        isDirty () {
            return this.$store.get('form/isDirty')
        }
    },
    // Found in: https://stackoverflow.com/a/56551646
    beforeRouteLeave (to, from, next) {
        if (this.isDirty) {
            const answer = window.confirm('Unsaved changes! Do you really want to leave?')
            
            // if the form is dirty and the user did not confirm leave,
            // prevent losing unsaved changes by cancelling navigation.
            if (!answer) {
                next(false)
            } else {
                next()
            }
        } else {
            // Navigate to next view
            next()
        }
    },
    created () {
        window.addEventListener('beforeunload', this.beforeWindowUnload)
    },
    beforeDestroy () {
        window.removeEventListener('beforeunload', this.beforeWindowUnload)
    }
}

export default UnsavedGuard